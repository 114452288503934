import React, { useState, useEffect } from "react";
import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { Circle, Bar } from "@visx/shape";
import { useDrag, Drag } from "@visx/drag";
import { scaleLinear } from "@visx/scale";
import { convertSeconds } from "../../../utils/utils";
import TrackControls from "./TrackControls";
import "./../../../App.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faToggleOn,
  faToggleOff,
  faInfinity,
  faTrash,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { size } from "lodash";
import { Box, Flex, Progress } from "@chakra-ui/react";

interface Loop {
  name: string;
  startTime: number;
  endTime: number;
  isSet: boolean;
}

function Timeline({
  duration,
  currentTime,
  player,
  loops,
  selectedLoopIndex,
  setLoops,
  setSelectedLoopIndex,
}: {
  duration: number | null;
  currentTime: number;
  player: any;
  loops: Loop[];
  selectedLoopIndex: number;
  setLoops: any;
  setSelectedLoopIndex: any;
}) {
  const wrapperDiv = React.useRef<HTMLDivElement>(null);
  const rightDiv = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [playHeadX, setPlayHeadX] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const height = 400;

  useEffect(() => {
    if (wrapperDiv.current) {
      // used to be wrapp
      const timeline = wrapperDiv.current;
      setWidth(timeline.offsetWidth - 140); // 140 is the width of the left div
    }
  }, [wrapperDiv.current, rightDiv.current]);

  useEffect(() => {
    if (!isDragging && duration) {
      setPlayHeadX((currentTime / duration) * width);
    }
  }, [currentTime, isDragging, duration, width]);

  if (!duration) return null;

  // every 10 seconds
  let div = 10;
  let ticks = duration / div;
  let tickDistance = width / ticks;

  // if over 5 minutes, every 30 seconds
  if (duration > 60 * 5) {
    div = 60;
    ticks = duration / div;
    tickDistance = width / ticks;
  }
  // every 10 seconds
  if (duration > 60 * 10) {
    div = 60 * 5;
    ticks = duration / div;
    tickDistance = width / ticks;
  }

  const punchLineColor = "#deb887";
  const punchLineColorDisabled = "#997788";
  const loopBarColor = "#598deb";
  const loopBarColorDisabled = "#009988";
  const red = "#ff0000";
  const trackBGColor = "#dbdbdb ";
  const trackBGColorSelected = "#1ED760"; //rgb(204 237 201)";//"#f2f2f2";
  const trackBGColorDisabled = "darkgray";
  const dotColorSelected = "orange";
  const dotColorDisabled = "#e6e6e6";

  const sizes = {
    top: 20,
    width: width,
    height: height,
    topRulerY: 15,
    bottomRulerY: 45,
    topTickY: 15,
    bottomTickY: 45,
    topLoopY: 20,
    bottomLoopY: 60,
    spaceBetween: 50,
  };

  const clickedOnTimeline = (e: any) => {
    e.stopPropagation();
    if (!wrapperDiv.current || !rightDiv.current) return;
    const rect = rightDiv.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const ratio = x / width;
    const seekTo = duration * ratio;

    //select the loop that was clicked on
    // const loopIndex = Math.floor(y / sizes.spaceBetween);
    // try {
    //   setSelectedLoopIndex(loopIndex);
    // } catch (err) {
    //   console.log(err);
    // }

    player.current.seekTo(seekTo);
  };

  return (
    <Flex ref={wrapperDiv}>
      <Box className="left" w="140px" pt={`${sizes.topRulerY}px`}>
        {loops.map((loop, i) => {
          return (
            <TrackControls
              key={`track-controls-${i}`}
              sizes={sizes}
              i={i}
              loop={loop}
              setLoops={setLoops}
              loops={loops}
              setSelectedLoopIndex={setSelectedLoopIndex}
              selectedLoopIndex={selectedLoopIndex}
              player={player}
              trackBGColorSelected={trackBGColorSelected}
              trackBGColorDisabled={trackBGColorDisabled}
              dotColorSelected={dotColorSelected}
              dotColorDisabled={dotColorDisabled}
            />
          );
        })}
      </Box>

      <Box
        className="right"
        ref={rightDiv}
        onClick={(e) => clickedOnTimeline(e)}
      >
        <svg width={width} height={sizes.spaceBetween * loops.length + 30}>
          <Group top={0}>
            {/* top horizontal line */}
            {/* <Line
              from={{ x: 0, y: sizes.topRulerY }}
              to={{ x: width, y: sizes.topRulerY }}
              stroke="#000"
              strokeWidth={1}
            /> */}

            {/* backgrounds */}
            {loops.map((loop, i) => {
              const loopWidth =
                ((loop.endTime - loop.startTime) / duration) * width;
              const loopX = (loop.startTime / duration) * width;
              const widthToCurrentTime =
                ((currentTime - loop.startTime) / duration) * width;
              return (
                <Group key={`loop-${i}`} top={i * sizes.spaceBetween}>
                  <Bar
                    x={0}
                    y={15}
                    width={width}
                    height={sizes.spaceBetween - 1}
                    fill={
                      i === selectedLoopIndex
                        ? trackBGColorSelected
                        : trackBGColorDisabled
                    }
                  />
                </Group>
              );
            })}

            {/* vertical lines - time ticks */}
            {Array.from({ length: ticks + 1 }, (_, i) => {
              const seconds = i * div;
              return (
                <Group key={`group${i}`}>
                  <Line
                    key={i}
                    from={{ x: i * tickDistance, y: sizes.topTickY }}
                    to={{
                      x: i * tickDistance,
                      y: sizes.topTickY + sizes.spaceBetween * loops.length,
                    }}
                    stroke="#000"
                    strokeWidth={0.5}
                  />
                  <Text
                    key={`text-${i}`}
                    x={i * Math.floor(tickDistance)}
                    y={10}
                    textAnchor="middle"
                    fontSize={11}
                    stroke="#000"
                  >
                    {convertSeconds(seconds)}
                  </Text>
                </Group>
              );
            })}

            {/* loops */}
            {loops.map((loop, i) => {
              const loopWidth =
                ((loop.endTime - loop.startTime) / duration) * width;
              const loopX = (loop.startTime / duration) * width;
              const widthToCurrentTime =
                ((currentTime - loop.startTime) / duration) * width;
              return (
                <Group key={`loop-${i}`} top={i * sizes.spaceBetween}>
                  {
                    // when punching in
                    !loop.isSet && loop.startTime !== -1 && (
                      <Group>
                        <Bar
                          x={loopX}
                          y={sizes.topLoopY}
                          width={widthToCurrentTime}
                          height={sizes.bottomLoopY - sizes.topLoopY}
                          fill={
                            i === selectedLoopIndex
                              ? loopBarColor
                              : loopBarColorDisabled
                          }
                          onClick={(e) => {
                            player.current.seekTo(loop.startTime);
                          }}
                          z={100}
                        />
                        <Line
                          from={{ x: loopX, y: sizes.topLoopY }}
                          to={{ x: loopX, y: sizes.bottomLoopY }}
                          stroke={
                            i === selectedLoopIndex
                              ? punchLineColor
                              : punchLineColorDisabled
                          }
                          strokeWidth={2}
                        />
                        <Line
                          from={{
                            x: loopX + widthToCurrentTime,
                            y: sizes.topLoopY,
                          }}
                          to={{
                            x: loopX + widthToCurrentTime,
                            y: sizes.bottomLoopY,
                          }}
                          stroke={
                            i === selectedLoopIndex
                              ? punchLineColor
                              : punchLineColorDisabled
                          }
                          strokeWidth={2}
                        />
                      </Group>
                    )
                  }
                  {loop.isSet && (
                    <Group>
                      <Bar
                        x={loopX}
                        y={sizes.topLoopY}
                        width={loopWidth}
                        height={sizes.bottomLoopY - sizes.topLoopY}
                        fill={
                          i === selectedLoopIndex
                            ? loopBarColor
                            : loopBarColorDisabled
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          player.current.seekTo(loop.startTime);
                        }}
                        z={100}
                      />
                      <Line
                        from={{ x: loopX, y: sizes.topLoopY }}
                        to={{ x: loopX, y: sizes.bottomLoopY }}
                        stroke={
                          i === selectedLoopIndex
                            ? punchLineColor
                            : punchLineColorDisabled
                        }
                        strokeWidth={2}
                      />
                      <Line
                        from={{ x: loopX + loopWidth, y: sizes.topLoopY }}
                        to={{ x: loopX + loopWidth, y: sizes.bottomLoopY }}
                        stroke={
                          i === selectedLoopIndex
                            ? punchLineColor
                            : punchLineColorDisabled
                        }
                        strokeWidth={2}
                      />
                      <Text
                        x={loopX + loopWidth + 10}
                        y={35}
                        textAnchor="start"
                        fontSize={15}
                      >
                        {loop.name}
                      </Text>
                    </Group>
                  )}

                  {/* bottom horizontal line */}
                  {/* <Line
                    from={{ x: 0, y: sizes.bottomRulerY }}
                    to={{ x: width, y: sizes.bottomRulerY }}
                    stroke="#000"
                    strokeWidth={1}
                  /> */}
                </Group>
              );
            })}
            {/* playhead */}
            <Group>
              <Circle
                cx={(currentTime / duration) * width} // isDragging ? playHeadX :
                cy={sizes.topRulerY}
                r={6}
                fill={red}
                className="my-circle"
              />
              <Line
                from={{
                  x: (currentTime / duration) * width,
                  y: sizes.topRulerY,
                }}
                to={{
                  x: (currentTime / duration) * width,
                  y: sizes.topTickY + sizes.spaceBetween * loops.length,
                }}
                stroke={red}
                strokeWidth={3}
              ></Line>
            </Group>
          </Group>
        </svg>
      </Box>
    </Flex>
  );
}

export default Timeline;
