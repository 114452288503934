export const addToStorage = ( id: string, title:string) => {
  let collection: any[] | null = JSON.parse(localStorage.getItem("loopty-vids") || "null");
  if (!collection) {
    collection = [];
  }
  if( collection.length === 0 || collection[collection.length - 1].id !== id) {
    collection.push({
      id: id,
      date: new Date().getTime(),
      title: title
    });

    // const uniqueData = collection.reduce((acc, item) => {
    //   if (!acc.find((i:any) => i.id === item.id)) {
    //     acc.push(item);
    //   }
    //   return acc;
    // }, []);

    // console.log(uniqueData);
    
    localStorage.setItem("loopty-vids", JSON.stringify(collection));
  }
}


export const getFromStorage = () => {
  let collection: any[] | null = JSON.parse(localStorage.getItem("loopty-vids") || "null");
  if (!collection) {
    collection = [];
  }
  const col = collection.reverse().slice(0, 10);
  const uniqueData = col.reduce((acc, item) => {
    if (!acc.find((i:any) => i.id === item.id)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueData;
}



