import React from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
// import { Routes, Route, Outlet, Link } from "react-router-dom";
import Layout from "./pages/Layout";
import Search from "./pages/search/Search";
import Play from "./pages/play/Play";
import { UserAuthContextProvider } from "./context/UserContext";
import Signup from "./pages/login/Signup";
// import { Login } from './pages/login/Login';
import Login from "./pages/login/LoginNew";
import My from "./pages/my/ListOfVideos";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

root.render(
  <ChakraProvider>
    <UserAuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Search />} />
            {/* <Route path='login' element={<Login />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="my" element={<My type="my" />} />
            <Route path="my/:id" element={<Play type="my" />} />
            <Route path="top" element={<My type="top" />} />
            <Route path="signup" element={<Signup />} />
            <Route path="new/:id" element={<Play type="new" />} />
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserAuthContextProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
