import { useState, useEffect } from "react";
import { getVideos, deleteVideo } from "../../service/firebase-service";
import { useUserAuth } from "../../context/UserContext";
import { convertSeconds } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Heading } from "@chakra-ui/react";
import Thumb from "./Thumbnail";
import { ILoop } from "./../../types/Types";

interface iMyLoops {
  type: string;
}

const ListOfVideos = (pref: iMyLoops) => {
  const [loops, setLoops] = useState<ILoop[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const user = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = getVideos(pref.type, (videos: any) => {
      setLoops(videos);
      setLoading(false);
    });

    return () => {
      if (unsub) unsub();
    };
  }, [user, pref.type]);

  // You can also use different thumbnail sizes by changing maxresdefault to default, mqdefault, hqdefault, or sddefault.

  return (
    // className="my-loops-wrapper"
    <Box>
      <Heading size="lg">
        {pref.type === "top" ? "Top Loops" : "My Loops"}
      </Heading>
      <Flex
        mt={5}
        className="items-wrapper"
        gap={5}
        alignContent={"flex-start"}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
        flexDirection={"row"}
      >
        {!loading &&
          loops.map((loop: ILoop, i) => (
            //console.log("loop", loop)
            <Thumb key={i} loop={loop} />
          ))}
      </Flex>
    </Box>
  );
};

export default ListOfVideos;

// console.log("useEffect my loops star");

// let fn = loop.type === "top" ? getTopVideos : getMyVideos;

// const unsubscribe = fn()
//   .then((videos: any) => {
//     if (!videos) return;
//     console.log(loop.type + "videos", videos);
//     setLoops(videos);
//     setLoading(false);
//   })
//   .catch((error: any) => {
//     console.log("error", error);
//   });
//console.log("unsubscribe", unsubscribe);

// Set up a Firestore real-time listener
// const unsubscribe = firestore.collection('yourCollectionName').onSnapshot((snapshot) => {
//   const updatedData = snapshot.docs.map((doc) => ({
//     id: doc.id,
//     ...doc.data(),
//   }));
//   setData(updatedData);
// });

// // Cleanup the listener when the component unmounts
// return () => unsubscribe();

//let fn = loop.type === "top" ? getTopVideos : getMyVideos;
