import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

interface IControls {
  player: any; // This should ideally have a specific type rather than 'any'
  isPlaying: boolean;
  currentTime: number;
  setStartTime: (time: number) => void;
  setEndTime: (time: number) => void;
  loops: any[]; // 'any' type should be replaced with a specific type
  setSelectedLoopIndex: (index: number) => void;
  selectedLoopIndex: number;
  adjustStartTimeDown: (down: boolean) => void;
  adjustEndTimeDown: (down: boolean) => void;
  clearLoops: () => void;
  addLoop: () => void;
  setLoops: (loops: any[]) => void;
  save: () => void;
  changeSpeed: () => void;
  currentSpeed: number;
  duration: number;
}

const Controls: React.FC<IControls> = ({
  player,
  isPlaying,
  currentTime,
  setStartTime,
  setEndTime,
  loops,
  selectedLoopIndex,
  setSelectedLoopIndex,
  adjustStartTimeDown,
  adjustEndTimeDown,
  clearLoops,
  addLoop,
  setLoops,
  save,
  changeSpeed,
  currentSpeed,
  duration,
}) => {
  const togglePlay = () => {
    const method = isPlaying ? "pauseVideo" : "playVideo";
    player.current?.[method]();
  };

  const seekTime = (offset: number) => {
    player.current?.seekTo(currentTime + offset);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${Math.round(secondsLeft).toString().padStart(2, "0")}`;
  };

  const punchTime = (time: number) => (time !== -1 ? time.toFixed(2) : "");
  const playbackTimeToDisplay = `${formatTime(currentTime)} / ${formatTime(
    duration
  )}`;

  return (
    <Flex className="controls" gap={2} justifyContent="space-between">
      {/* play, speed */}
      <Flex gap={2}>
        <Box className="control-wrapper">
          <Button
            onClick={togglePlay}
            size="md"
            colorScheme="teal"
            variant="outline"
          >
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </Button>
        </Box>
        <Box>
          <Button onClick={changeSpeed} colorScheme="teal" variant="outline">
            Speed {currentSpeed}X
          </Button>
        </Box>
        <Box w="130px">
          <Button w="130px" colorScheme="teal" variant="outline">
            <Text size="xl">{playbackTimeToDisplay}</Text>
          </Button>
        </Box>
      </Flex>
      {/* punch in out */}
      <Flex gap={2}>
        <PunchButton
          label="Punch In"
          time={punchTime(loops[selectedLoopIndex].startTime)}
          setTime={() => setStartTime(currentTime)}
          adjustTime={(down) => adjustStartTimeDown(down)}
        />
        <PunchButton
          label="Punch Out"
          time={punchTime(loops[selectedLoopIndex].endTime)}
          setTime={() => setEndTime(currentTime)}
          adjustTime={(down) => adjustEndTimeDown(down)}
        />
      </Flex>
      {/* clear, add, save */}
      <Flex gap={2}>
        <ActionButton onClick={clearLoops} label="Clear Loop" />
        <ActionButton onClick={addLoop} label="Add Loop" />
        <ActionButton onClick={save} label="Save" />
      </Flex>
    </Flex>
  );
};

interface PunchButtonProps {
  label: string;
  time: string;
  setTime: () => void;
  adjustTime: (down: boolean) => void;
}

const PunchButton: React.FC<PunchButtonProps> = ({
  label,
  time,
  setTime,
  adjustTime,
}) => (
  <Box>
    <HStack>
      <Button size="sm" onClick={() => adjustTime(true)}>
        -
      </Button>
      <Button
        size="md"
        onClick={setTime}
        pr={0}
        pl={0}
        colorScheme="teal"
        variant="outline"
      >
        {label} {time}
      </Button>
      <Button size="sm" onClick={() => adjustTime(false)}>
        +
      </Button>
    </HStack>
  </Box>
);

interface ActionButtonProps {
  onClick: () => void;
  label: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, label }) => (
  <Box>
    <Button
      className="large-btn"
      onClick={onClick}
      colorScheme="teal"
      variant="outline"
    >
      {label}
    </Button>
  </Box>
);

export default Controls;
