import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDW79Mb-itYBXqPqBp8H4r4U0ENtOFM6kU",
  authDomain: "loopy-48fac.firebaseapp.com",
  projectId: "loopy-48fac",
  storageBucket: "loopy-48fac.appspot.com",
  messagingSenderId: "733508440676",
  appId: "1:733508440676:web:4bd672651974ca66580571",
  measurementId: "G-PSE5SSVM1N",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

// const unsubscribe = auth.onAuthStateChanged(function(user) {
//   if (user) {
//     // User is signed in.
//     console.log("User is signed in:", user);
//   } else {
//     // User is signed out.
//     console.log("User is signed out.");
//   }
// });

export const saveVideo = async (videoId: string, player: any, loops: any) => {
  const user = auth.currentUser;
  if (user) {
    const docRef = await db.collection("loops").add({
      loops: loops,
      videoId,
      data: player.getVideoData(),
      duration: player.getDuration(),
      userId: user.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    console.log("Document written with ID: ", docRef.id);
  }
};

export const getVideos = (type: string, setData: Function) => {
  const user = auth.currentUser;

  let collectionRef = null;
  if (type === "top") {
    collectionRef = db.collection("loops").orderBy("createdAt", "desc");
  } else if (type === "my" && user?.uid) {
    collectionRef = db
      .collection("loops")
      .where("userId", "==", user.uid)
      .orderBy("createdAt", "asc");
  }
  if (!collectionRef) return;

  const unsubscribe = collectionRef.onSnapshot((snapshot) => {
    const updatedData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(updatedData);
  });
  return unsubscribe;
};

export const deleteVideo = async (id: string) => {
  const user = auth.currentUser;
  if (user) {
    await db.collection("loops").doc(id).delete();
  }
};

export const getTopVideos = async () => {
  const querySnapshot = await db
    .collection("loops")
    .orderBy("createdAt", "desc")
    // .limit(10)
    .get();
  const loops = querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  return loops;
};

export const saveUser = async (userCredential: any) => {
  // check if user uid exists in db
  const userRef = await db
    .collection("users")
    .where("uid", "==", userCredential.user.uid)
    .get();
  if (userRef.docs.length > 0) {
    return;
  }
  return firebase.firestore().collection("users").add({
    email: userCredential.user.email,
    uid: userCredential.user.uid,
    providerId: userCredential.user.providerId,
    displayName: userCredential.user.displayName,
    photoURL: userCredential.user.photoURL,
    emailVerified: userCredential.user.emailVerified,
    phoneNumber: userCredential.user.phoneNumber,
    isAnonymous: userCredential.user.isAnonymous,
    tenantId: userCredential.user.tenantId,
    providerData: userCredential.user.providerData,
  });
};

export default firebase;

// if (user) {
// const querySnapshot = await db
//   .collection("loops")
//   .where("userId", "==", user.uid)
//   .orderBy("createdAt", "desc")
//   .get();
// const loops = querySnapshot.docs.map((doc) => {
//   return { id: doc.id, ...doc.data() };
// });
// return loops;
