import React from 'react';

export function convertSeconds(input:number):string {
  var minutes = Math.floor(input / 60);
  var seconds:number = input % 60;

  //seconds = 
  const secondsPrefix = seconds < 10 ? '0' : '';
  return minutes + ":" + secondsPrefix + seconds.toFixed(0);
}

export const checkHowManyLoops = (loops: any[]) => {
  let count = 0;
  loops.forEach((loop: any) => {
    if (loop.isSet) {
      count++;
    }
  })
  return count;
}
