import { Avatar, Box, Button, Flex, IconButton } from "@chakra-ui/react";
import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { deleteVideo } from "../../service/firebase-service";
import { convertSeconds } from "../../utils/utils";
import { ILoop } from "./../../types/Types";
import {
  Card,
  CardBody,
  CardHeader,
  Stack,
  Text,
  Heading,
  StackDivider,
  Image,
} from "@chakra-ui/react";
import { SyntheticEvent } from "react";

const confirmText = `Are you sure you want to delete this loop? It will be deleted permanently. `;

const Thumb = ({ loop }: { loop: ILoop }) => {
  const navigate = useNavigate();

  const deleteAVideo = (id: string, e: SyntheticEvent) => {
    e.stopPropagation();
    if (window.confirm(confirmText)) {
      try {
        deleteVideo(loop.id);
        //console.log("deleted");
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Box
      w="230px"
      gap={3}
      onClick={() => {
        navigate(`/my/${loop.id}`);
      }}
    >
      <Box position="relative">
        <Image
          src={`https://img.youtube.com/vi/${loop.videoId}/sddefault.jpg`}
          alt="Green double couch with wooden legs"
          borderRadius="lg"
        />
        <Flex
          p={2}
          w="full"
          justifyContent="space-between"
          position="absolute"
          bottom="0px"
        >
          <Box onClick={(e) => deleteAVideo(loop.id, e)}>
            <IconButton
              colorScheme="teal"
              aria-label="Call Segun"
              size={"xs"}
              icon={<DeleteIcon />}
            />
          </Box>
          <Box onClick={(e) => deleteAVideo(loop.id, e)}>
            <Button colorScheme="teal" aria-label="Call Segun" size={"xs"}>
              {loop.duration ? convertSeconds(loop.duration) : "00:00"}
            </Button>
          </Box>
        </Flex>
      </Box>

      <Flex w="full" mt={2} gap={2}>
        <Avatar
          mt={2}
          size="sm"
          name="Dan Abrahmov"
          src={`https://img.youtube.com/vi/${loop.videoId}/sddefault.jpg`}
        />
        <Box>
          <Text
            fontSize="md"
            lineHeight={5}
            h={10}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {loop.data.title}
          </Text>
          <Flex mt={2} width="full" justifyContent="space-between">
            <Box>
              <Text fontSize="sm">2 years ago</Text>
              {/* <Text fontSize="sm">AG Loops / GuitarWorld</Text> */}
            </Box>
            <Box>
              <Text fontSize="sm">{`${loop.loops.length} loops`}</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Text pt="2" fontSize="sm"></Text>
    </Box>
  );
};

export default Thumb;
