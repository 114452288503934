import { Outlet, Link } from "react-router-dom";
// import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useContext } from "react";
import { useUserAuth } from "../context/UserContext";
import firebase from "./../service/firebase-service";

import {
  Avatar,
  Box,
  Button,
  Center,
  Progress,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { Heading, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { UnorderedList, ListItem } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";

const logout = () => {
  firebase.auth().signOut();
};

function Layout() {
  const { user } = useUserAuth();

  return (
    <Box w="full" pl={5} pr={5}>
      <Flex h={"50px"} gap={1} justifyContent={"space-between"}>
        {/* A "layout route" is a good place to put markup you want to
              share across all the pages on your site, like navigation. */}
        <Box w={"250px"}>
          {/* <Center> */}
          <Heading as="h2" size="xl">
            <Text color="teal.500">
              <Link to="/">LoopiTube</Link>
            </Text>
          </Heading>
          {/* </Center> */}
        </Box>

        <Flex>
          <Box p={"10px"}>
            <Link to="/top">Top Loops for Guitar</Link>
          </Box>
          <Box p={"10px"}>
            <Link to="/top">Top Loops for Piano</Link>
          </Box>
          {user.uid && (
            <Box p={"10px"}>
              <Link to="/my">My Loops</Link>
            </Box>
          )}

          <Box p={"10px"}>
            <Link to="/top">How to Use</Link>
          </Box>
        </Flex>

        <Box>
          <Flex>
            {/* <Box p={"10px"}>
              <Link to="/my">My Loops</Link>
            </Box> */}

            {!user.uid && (
              <>
                <Box p={"10px"}>
                  <Link to="/login">Login</Link>
                </Box>
                <Box p={"10px"}>
                  <Link to="/signup">Signup</Link>
                </Box>
              </>
            )}

            {user.uid && (
              <>
                <Box p={"10px"}>
                  <Menu>
                    {/* <MenuButton as={Button} colorScheme="pink">
                      Profile
                    </MenuButton> */}
                    <Avatar
                      as={MenuButton}
                      src="https://bit.ly/sage-adebayo"
                      size="sm"
                      name={user.email}
                    />
                    <MenuList>
                      <MenuGroup title={user.email}>
                        <MenuItem>My Account</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuGroup>
                      {/* <MenuDivider />
                      <MenuGroup title="Help">
                        <MenuItem>Docs</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </MenuGroup> */}
                    </MenuList>
                  </Menu>

                  {/* <Link to="/account">
                    <Avatar
                      src="https://bit.ly/sage-adebayo"
                      size="sm"
                      name={user.email}
                    />
                  </Link> */}
                  {/* <Link to="/account">{user.email}</Link> */}
                </Box>
                {/* <Box p={"10px"}>
                  <Link to="/" onClick={logout}>
                    Logout
                  </Link>
                </Box> */}
              </>
            )}
          </Flex>
        </Box>

        {}
      </Flex>

      {/* An <Outlet> renders whatever child route is currently active,
              so you can think about this <Outlet> as a placeholder for
              the child routes we defined above. */}

      <Box w={"full"} mt={"20px"}>
        <Outlet />
      </Box>

      {/* <Box w={"full"} mt={"20px"} textAlign="center">
        <Text fontSize="sm">
          Made with ❤️ in New Jersey, USA
          <br />
          <Link to="/about">About</Link> |{" "}
          <Link to="/terms">Terms of Service</Link> |{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </Text>
      </Box> */}
    </Box>
  );
}

export default Layout;

{
  /* <Box>
<Alert status="error" alignItems={"center"}>
  <AlertIcon />

  <AlertDescription>
    Try another Youtube URL or search for a video
  </AlertDescription>
</Alert>
</Box> */
}

// {
//   !user.uid && (
//     <>
//       <li>
//         <Link to="/login">Login</Link>
//       </li>
//       <li>
//         <Link to="/signup">Signup</Link>
//       </li>
//     </>
//   );
// }

// {
//   user.uid && (
//     <>
//       <li>
//         <Link to="/my">My Loops</Link>
//       </li>

//       <li>
//         <Link to="/account">{user.email}</Link>
//       </li>
//       <li>
//         <Link to="/" onClick={logout}>
//           Logout
//         </Link>
//       </li>
//     </>
//   );
// }
