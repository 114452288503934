import React, { useState, useEffect, useRef, DOMElement } from "react";
import { useParams } from "react-router-dom";

import Timeline from "./timeline/Timeline";
import { convertSeconds } from "../../utils/utils";
import { addToStorage } from "./utils";
import { db } from "../../service/firebase-service";
import { auth, saveVideo } from "../../service/firebase-service";
import { useUserAuth } from "../../context/UserContext";
import { checkHowManyLoops } from "./../../utils/utils";
import Controls from "./controls/PlaybackControls";
import LoopsList from "./LoopsList";
import { Box, Center, Flex, Heading, Progress, VStack } from "@chakra-ui/react";
import VideoPlayer from "./video/VideoPlayer";
import { Badge, Stack } from "@chakra-ui/react";

interface Loop {
  name: string;
  startTime: number;
  endTime: number;
  isSet: boolean;
}

interface IPlay {
  type?: string;
}

function Play({ type }: IPlay) {
  // use state to store current time and duration
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(-1);
  const [currentSpeed, setCurrentSpeed] = useState<number>(1);

  const speedsArray = [1, 0.9, 0.8, 0.7, 0.6, 0.5];

  // use state to store loop information
  const [loops, setLoops] = useState<Loop[]>([
    {
      startTime: -1,
      endTime: -1,
      isSet: false,
      name: "loop 1",
    },
  ]);
  const [selectedLoopIndex, setSelectedLoopIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // use ref to store reference to player object
  const player = useRef<any>(null);
  // use params to get video ID from the URL

  let { id } = useParams();
  const { user } = useUserAuth();

  const [videoId, setVideoId] = useState<string | null>(null);

  useEffect(() => {
    const iframe: any = document.querySelector(".left-side iframe");
    if (!iframe) return;

    iframe.addEventListener("keydown", (e: any) => {
      debugger;
      if (e.key === "Space") {
        // prevent default behavior of spacebar
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }, [player]);

  useEffect(() => {
    if (!id) return;
    if (!player.current) return;

    if (type === "new") addToStorage(id, player.current.videoTitle);
    //console.log('video Id changed to', id);
  }, [id, player.current]);

  useEffect(() => {
    if (type !== "my") {
      if (!id) return;
      setVideoId(id);
      return;
    }
    if (!id) return;

    const unsubscribe = db
      .collection("loops")
      .doc(id)
      .onSnapshot((doc) => {
        id = doc.data()?.videoId;
        if (!id) return;
        //debugger
        setVideoId(id);
        setLoops(doc.data()?.loops);
        //console.log("Current data: ", doc.data());
      });

    return unsubscribe;
  }, [type]);

  // useEffect to handle side effects
  useEffect(() => {
    const interval = setInterval(() => {
      if (player.current && duration !== -1) {
        const currTime = player.current.getCurrentTime();
        setCurrentTime(currTime);
        // //console.log();
      }
    }, 100);
    return () => clearInterval(interval);
  }, [duration]);

  useEffect(() => {
    if (!player.current) return;

    // see if we are in a loop
    const selectedLoop = loops[selectedLoopIndex];
    if (!selectedLoop) return;

    const isInLoop =
      selectedLoop.isSet &&
      currentTime >= selectedLoop.startTime &&
      currentTime <= selectedLoop.endTime + 0.2; // add 0.2 to account for rounding errors

    if (isInLoop) {
      // if at the end of the loop, go back to the start
      if (currentTime >= selectedLoop.endTime) {
        player.current.seekTo(selectedLoop.startTime);
      }
      //console.log("in loop");
    } else {
      //console.log("not in loop");
    }
  }, [player, currentTime, loops, selectedLoopIndex]);

  // function to add a new loop
  const addLoop = () => {
    setLoops((prev) => {
      const newLoops = [...prev];
      newLoops.splice(selectedLoopIndex + 1, 0, {
        startTime: -1,
        endTime: -1,
        isSet: false,
        name: `loop ${loops.length + 1}`,
      });

      setSelectedLoopIndex(selectedLoopIndex + 1);

      return newLoops;
    });
  };

  const adjustTimeAmount = 0.05;

  const adjustStartTimeDown = (down: boolean) => {
    const newLoops = [...loops];

    let timeInQuestion = newLoops[selectedLoopIndex].startTime;
    timeInQuestion += down ? -adjustTimeAmount : adjustTimeAmount;

    newLoops[selectedLoopIndex].startTime = timeInQuestion;
    player.current.seekTo(timeInQuestion);

    setLoops(newLoops);
  };

  const adjustEndTimeDown = (down: boolean) => {
    const newLoops = [...loops];

    let timeInQuestion = newLoops[selectedLoopIndex].endTime;
    timeInQuestion += down ? -adjustTimeAmount : adjustTimeAmount;

    newLoops[selectedLoopIndex].endTime = timeInQuestion;
    player.current.seekTo(timeInQuestion - 2);

    setLoops(newLoops);
  };

  const setStartTime = (time: number) => {
    setLoops((prevLoops) =>
      prevLoops.map((loop, index) =>
        index === selectedLoopIndex
          ? { ...loop, startTime: time, isSet: false }
          : loop
      )
    );
  };

  // function to set loop end time
  const setEndTime = (time: number) => {
    const newLoops = [...loops];
    newLoops[selectedLoopIndex].endTime = time;
    newLoops[selectedLoopIndex].isSet =
      newLoops[selectedLoopIndex].startTime !== -1;
    if (newLoops[selectedLoopIndex].startTime !== -1) {
      player.current.seekTo(newLoops[selectedLoopIndex].startTime);
    }
    setLoops(newLoops);
  };

  const clearLoops = () => {
    setLoops((prevLoops) => {
      return prevLoops.map((loop, index) =>
        index === selectedLoopIndex
          ? { ...loop, startTime: -1, endTime: -1, isSet: false }
          : loop
      );
    });
  };

  const changeSpeed = () => {
    setCurrentSpeed((prevSpeed) => {
      const speedIndex = speedsArray.indexOf(prevSpeed);
      const newSpeed = speedsArray[(speedIndex + 1) % speedsArray.length];
      player.current?.setPlaybackRate(newSpeed);
      return newSpeed;
    });
  };
  const playerSize = {
    height: 300,
    width: 640,
    ratio: 1.3,
  };

  const save = async () => {
    // Check for user authentication and required IDs
    if (!user.uid || !id || !videoId) {
      // Set some state here to show a message in the UI that the user needs to log in.
      // This avoids using window.alert and is more in line with React's declarative nature.
      console.log("Register or login to save loops"); // Replace with a state setter
      return;
    }

    // Check if there are loops to save
    if (!checkHowManyLoops(loops)) {
      // Set some state here to show a message in the UI that there are no loops to save.
      console.log("No loops to save"); // Replace with a state setter
      return;
    }

    // Attempt to save the video
    try {
      await saveVideo(videoId, player.current, loops);
      // You can set some state here to show a success message.
    } catch (error) {
      // Handle the error case, possibly by setting an error state and displaying it in the UI.
      console.error("Error saving video: ", error); // Replace with error handling logic
    }
  };

  return (
    <Box w="full">
      <VStack w="full">
        <Flex w="full" gap={2}>
          <Box
            w={`${playerSize.width * playerSize.ratio}`}
            h={`${playerSize.height * playerSize.ratio}`}
          >
            {!!id && !!videoId && (
              <VideoPlayer
                videoId={videoId}
                playerSize={playerSize}
                loops={loops}
                selectedLoopIndex={selectedLoopIndex}
                setSelectedLoopIndex={setSelectedLoopIndex}
                setDuration={setDuration}
                setIsPlaying={setIsPlaying}
                player={player}
              />
            )}
          </Box>
          <Box flex={1}>
            {!!id && !!videoId && player.current && (
              <>
                <Heading size="md">{player.current.videoTitle}</Heading>
                <Box className="info-section">
                  <div className="row">Video By: MartyGuitar</div>
                  <div className="row">Remixed By: AG LOOPS</div>
                  <div className="row">Artist: Eagles</div>
                  <div className="row">Title: Hotel California</div>
                  <div className="row">Key: C</div>
                </Box>
                <Stack direction="row">
                  <Badge>Guitar</Badge>
                  <Badge>Guitar Lesson</Badge>
                  <Badge>Music Production</Badge>
                  <Badge>Scott Storch</Badge>
                </Stack>
              </>
            )}
          </Box>
        </Flex>
        <Box w="full">
          <Center>
            <Box width="1000px">
              <Controls
                duration={duration}
                player={player}
                isPlaying={isPlaying}
                currentTime={currentTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                loops={loops}
                selectedLoopIndex={selectedLoopIndex}
                setSelectedLoopIndex={setSelectedLoopIndex}
                setLoops={setLoops}
                clearLoops={clearLoops}
                addLoop={addLoop}
                save={save}
                changeSpeed={changeSpeed}
                currentSpeed={currentSpeed}
                adjustStartTimeDown={adjustStartTimeDown}
                adjustEndTimeDown={adjustEndTimeDown}
              />
            </Box>
          </Center>

          <Box mt={3}>
            <Box w={"full"}>
              {duration !== -1 && (
                <Timeline
                  currentTime={currentTime}
                  duration={duration}
                  player={player}
                  loops={loops}
                  setLoops={setLoops}
                  selectedLoopIndex={selectedLoopIndex}
                  setSelectedLoopIndex={setSelectedLoopIndex}
                />
              )}
            </Box>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

export default Play;
