import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AbsoluteCenter, Box, Center, Input } from "@chakra-ui/react";
import { faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

type TrackControlsProps = {
  sizes: any;
  i: number;
  loop: any;
  setLoops: any;
  loops: any;
  setSelectedLoopIndex: any;
  selectedLoopIndex: number;
  player: any;
  trackBGColorSelected: string;
  trackBGColorDisabled: string;
  dotColorSelected: string;
  dotColorDisabled: string;
};

const TrackControls = ({
  sizes,
  i,
  loop,
  setLoops,
  loops,
  setSelectedLoopIndex,
  selectedLoopIndex,
  player,
  trackBGColorSelected,
  trackBGColorDisabled,
  dotColorSelected,
  dotColorDisabled,
}: TrackControlsProps) => {
  return (
    <Box
      className="track-controls-wrapper"
      gap={2}
      style={{
        display: "flex",
        height: sizes.spaceBetween,
        background:
          i === selectedLoopIndex ? trackBGColorSelected : trackBGColorDisabled,
        padding: "5px",
        // background: repeating-linear-gradient(45deg, #abc8cf, transparent 100px);
      }}
      onClick={(e) => {
        console.log("clicked parent");
        setSelectedLoopIndex(i);
      }}
      key={`background-bar${i}`}
    >
      <Box className="selected-track-button-wrapper">
        <Box
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "20%",
            backgroundColor:
              i === selectedLoopIndex ? dotColorSelected : dotColorDisabled,
          }}
        ></Box>
      </Box>
      <Box w={"100px"}>
        <Input
          size={"xs"}
          className="track-name-input"
          type="text"
          value={loop.name}
          onChange={(e) => {
            const newLoops = [...loops];
            newLoops[i].name = e.target.value;
            setLoops(newLoops);
          }}
        />
      </Box>

      {/* <div className="each-track-control">
              <FontAwesomeIcon icon={faToggleOn} size="sm" color="gray" />
            </div> */}

      <Box className="each-track-control">
        <FontAwesomeIcon
          icon={faPlayCircle}
          size="lg"
          color="white"
          onClick={() => {
            console.log("clicked play");
            console.log(loop.startTime);
            player.current.seekTo(loop.startTime);
            player.current.playVideo();
          }}
        />
      </Box>
      {/* <div className="each-track-control">
            <FontAwesomeIcon icon={faInfinity} size="sm" color="black" />
          </div> */}
      <Box className="each-track-control">
        <FontAwesomeIcon
          icon={faTrash}
          size="lg"
          color="white  "
          onClick={() => {
            const newLoops = [...loops];
            newLoops.splice(i, 1);

            setSelectedLoopIndex(selectedLoopIndex - 1);
            setLoops(newLoops);
          }}
        />
      </Box>
    </Box>
  );
};

export default TrackControls;
