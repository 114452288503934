// create react component with an input field and save the value in a state

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../../App.css";
import { getFromStorage } from "../play/utils";
import { Box, Button, Center, HStack, Link, Text } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { UnorderedList, ListItem } from "@chakra-ui/react";

function Search() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const viewedList = getFromStorage();

  const goToVideo = (vidURL: string) => {
    const re =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
    const match = vidURL.match(re);
    if (match && match[1]) {
      navigate(`/new/${match[1]}`);
    }
  };
  return (
    <Box w={"full"} h={"full"}>
      <Box w={"full"} h={"600px"}>
        <Center w={"full"} h={"full"}>
          <Box>
            <Box>
              <HStack gap={2}>
                <Input
                  borderColor={"blue"}
                  w={"400px"}
                  placeholder="Enter Youtube URL or search for video "
                  value={search}
                  onChange={(e) => {
                    //debugger;
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    //e.preventDefault();
                    if (e.key === "Enter") {
                      goToVideo(search);
                    }
                    if (e.key === "Escape") {
                      setSearch("");
                    }
                    if (e.key === "v" && e.ctrlKey) {
                      // paste from clipboard
                      navigator.clipboard.readText().then((clipText) => {
                        setSearch(clipText);
                      });
                    }
                    if (search !== null && search.length) goToVideo(search);
                  }}
                />
                <Button
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => goToVideo(search)}
                >
                  Loop it!
                </Button>
              </HStack>
            </Box>
            <Box mt={3} textAlign="center">
              <Text fontSize="sm">Recently Played:</Text>
              <UnorderedList className="viewed-list">
                {viewedList.map((item: any, index: any) => {
                  return (
                    <ListItem key={index} m={0} p={0}>
                      <Link href={`/new/${item.id}`}>
                        <Text fontSize="md">{item.title}</Text>
                      </Link>
                      {/* <a href={`/new/${item.id}`}>{item.title}</a> */}
                      {/* <img src={`https://img.youtube.com/vi/${item.id}/0.jpg`} alt={item.title} 
                    width="140" height="100" /> */}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

export default Search;
