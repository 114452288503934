import YouTube from "react-youtube";

type VideoPlayerProps = {
  videoId: string;
  playerSize: any;
  loops: any[];
  selectedLoopIndex: number;
  setSelectedLoopIndex: (index: number) => void;
  setDuration: (duration: number) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  player: any;
};
const VidoPlayer = ({
  videoId,
  playerSize,
  loops,
  selectedLoopIndex,
  setSelectedLoopIndex,
  setDuration,
  setIsPlaying,
  player,
}: VideoPlayerProps) => {
  return (
    <>
      <YouTube
        videoId={videoId}
        opts={{
          height: playerSize.height * playerSize.ratio,
          width: playerSize.width * playerSize.ratio,
          playerVars: {
            autoplay: 1,
          },
        }}
        onReady={(event) => {
          setDuration(event.target.getDuration());
          player.current = event.target;
          player.current.seekTo(loops[selectedLoopIndex].startTime | 0);
        }}
        onStateChange={(event) => {
          if (event.data === 1) {
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
          }
        }}
      />
      {/* <Heading as="h1" size="md ">
        {player.current && player.current.videoTitle}
      </Heading> */}
    </>
  );
};

export default VidoPlayer;
